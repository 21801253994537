(function () {
    'use strict';

    angular.module("loginApp")
        .config(LoginAppConfig);

    LoginAppConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$resourceProvider', "$locationProvider",
        "routesConstant"];

    function LoginAppConfig($stateProvider, $urlRouterProvider, $resourceProvider, $locationProvider, routesConstant) {
        $resourceProvider.defaults.stripTrailingSlashes = false;

        var LOGIN = routesConstant.LOGIN;

        $stateProvider
            .state(LOGIN.MAIN.stateName, {
                url: LOGIN.MAIN.url,
                controller: 'LoginController',
                templateUrl: '/static/templates/login/login.html'
            })
            .state(LOGIN.CUSTOMER.stateName, {
                url: LOGIN.CUSTOMER.url,
                controller: 'LoginController',
                templateUrl: '/static/templates/login/login.html'
            })
            .state(LOGIN.ADMIN.stateName, {
                url: LOGIN.ADMIN.url,
                controller: 'LoginController',
                templateUrl: '/static/templates/login/login.html',
                params: {
                    role: 'admin'
                }
            })
            .state(LOGIN.MFG.stateName, {
                url: LOGIN.MFG.url,
                controller: 'LoginController',
                templateUrl: '/static/templates/login/login.html',
                params: {
                    role: 'mfg'
                }
            })
            .state(LOGIN.LINK.stateName, {
                url: LOGIN.LINK.url,
                controller: 'LoginController',
                templateUrl: '/static/templates/login/login.html',
                params: {
                    role: 'link'
                }
            })
            .state(LOGIN.LOGOUT.stateName, {
                url: LOGIN.LOGOUT.url,
                controller: 'LogoutController'
            });

    }

})();

